import { Feature } from "ol";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import MarkerImage from '../assets/img/marker.png';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

const createMarker = (place) => {
  const marker = new Feature({
    geometry: new Point(fromLonLat([place.longitude, place.latitude])),
    place: place
  });
  marker.setStyle(new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: MarkerImage,
    }),
  }));
  return marker;
}

export const createMarkers = (places) => {
  const markers = places.map(place => createMarker(place));
  return new VectorLayer({
    source: new VectorSource({
      features: markers
    }),
    name: 'markers'
  });
}