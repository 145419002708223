import './User.css';
import { Overlay } from 'ol';
import { useEffect, useState } from 'react';
import { addPlace, deletePlace, editPlace, getPlaceRatings, getPlaceTypes, getPlaces, getRanking, ratePlace, nearestPlace } from '../../services/PlaceService';
import PlaceInputs from '../PlaceInputs';
import 'ol/ol.css';
import { fromLonLat, toLonLat } from 'ol/proj';
import Popup from '../Popup';
import PlacesMap from '../placesMap/PlacesMap';
import { map } from '../placesMap/PlacesMap';

let popup;
function handlePlaceSelectionOnMap({ setSelectedPlace }) {

  if(!map) { return; }

  map.removeOverlay(popup);
  popup = new Overlay({
      element: document.querySelector("#popup"),
      autoPan: true
  });
  map.addOverlay(popup);  

  map.on("click", event => {
    const marker = map.getFeaturesAtPixel(event.pixel)[0];
    if(marker) {
      const markerPlace = marker.getProperties().place;
      setSelectedPlace(markerPlace);
      return;
    } 

    const lonlat = map.getCoordinateFromPixel(event.pixel);
    const [roundedLon, roundedLat] = toLonLat(lonlat).map(coord => coord.toFixed(6));
    setSelectedPlace({ longitude: parseFloat(roundedLon), latitude: parseFloat(roundedLat) });
  });

  map.on("pointermove", event => {
    if(!event.dragging) {
      map.getTargetElement().style.cursor = map.hasFeatureAtPixel(map.getEventPixel(event.originalEvent)) ? 'pointer' : '';
    }
  })
}


const Rating = ({ rating, onStarClick }) => (
  <div className="rating-container">
    <p>Twoja ocena: {rating}/5</p>
    {[1, 2, 3, 4, 5].map((value) => (
      <span
        key={value}
        className={`star ${value <= rating ? 'filled' : ''}`}
        onClick={() => onStarClick(value)}
      >
        ★
      </span>
    ))}
  </div>
);


export default function User() {
  const [addEditContent, setAddEditContent] = useState(false);
  const [deleteContent, setDeleteContent] = useState(false);
  const [findNearestContent, setFindNearestContent] = useState(false);
  const [rankingsContent, setRankingsContent] = useState(false);
  const [rateContent, setRateContent] = useState(false);

  const [placeInputs, setPlaceInputs] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [selectedPlaceRatings, setSelectedPlaceRatings] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [rating, setRating] = useState(null);
  const [places, setPlaces] = useState(null);
  const [placeTypes, setPlaceTypes] = useState(null);
  const [ranking, setRanking] = useState(null);

  const [toggle, changeToggle] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [controlsActive, setControlsActive] = useState(false);

  const setToggle = value => {
    setContent(setAddEditContent);
    setSelectedPlace({});
    changeToggle(value);
  };

  const executeAddPlace = async() => {
    if(!placeInputs) {
      return;
    }
    if(!placeInputs.placeTypeId) {
      placeInputs.placeTypeId = placeTypes[0].id;
    }
    placeInputs.coordinates = {
      longitude: placeInputs.longitude,
      latitude: placeInputs.latitude
    }

    const result = await addPlace(placeInputs);
    if(result) {
      setToggle(prev => !prev);
      setShowSuccessPopup(true);
    }
  }
  const executeEditPlace = async() => {
    if(!placeInputs) {
      return;
    }

    const result = await editPlace(selectedPlace.id, placeInputs);
    if(result) {
      setToggle(prev => !prev);
    }
  }
  const executeRate = async() => {
    if(!rating) {
      return;
    }

    const result = await ratePlace(selectedPlace.id, rating);
    if(result) {
      setToggle(prev => !prev);
    }
  }
  const executeDeletePlace = async() => {
    if(!placeInputs) {
      return;
    }

    const result = await deletePlace(placeInputs.id);
    if(result) {
      setToggle(prev => !prev);
    }
  }

  const setContent = (toggleFunc) => {
    setAddEditContent(false);
    setDeleteContent(false);
    setFindNearestContent(false);
    setRankingsContent(false);
    setRateContent(false);
    toggleFunc && toggleFunc(true);
    setControlsActive(true);
  };
  const toggleContentMethods = {setContent, setAddEditContent, setDeleteContent, setRateContent};

  const handleStarClick = (value) => {
    setRating({...rating, score: value});
  };

  const findNearestPlace = async () => {
      if (!placeInputs) {
          return;
      }
      const nearestPlaceBody = {
          longitude: placeInputs.longitude,
          latitude: placeInputs.latitude
      }
      const result = await nearestPlace(nearestPlaceBody);
      if (result) {
          setSelectedPlace(result);
      }
  }

  useEffect(() => {
    getPlaces()
      .then(data => setPlaces(data));
    getPlaceTypes()
      .then(data => setPlaceTypes(data)); 
    getRanking()
      .then(data => setRanking(data));
  }, [toggle]);
  useEffect(() => {
    setPlaceInputs({...selectedPlace});
    if(!selectedPlace.id) {
      setEditMode(false);
      popup?.setPosition(undefined);
    }
    else {
      setEditMode(true);
      getPlaceRatings(selectedPlace.id)
        .then(data => setSelectedPlaceRatings(data));
      popup?.setPosition(fromLonLat([selectedPlace.longitude, selectedPlace.latitude]));
    }
  }, [selectedPlace]);
  useEffect(() => {
    handlePlaceSelectionOnMap({ setSelectedPlace });
  }, []);
  
  return (
    <>
      <div className='user-container'>
        <div className="map-container">
          <div id="map">
            <PlacesMap places={places} />
          </div>
          <div id="popup">
            <Popup place={selectedPlace} ratings={selectedPlaceRatings} setSelectedPlace={setSelectedPlace} toggleContentMethods={toggleContentMethods} />
          </div>
        </div>

        <div className={"controls" + (controlsActive ? " active" : "")}>
          <div className="buttons-container">
            <button onClick={() => setContent(setAddEditContent)}>{editMode ? "Edytuj miejsce" : "Dodaj miejsce"}</button>
            <button onClick={() => setContent(setFindNearestContent)}>Najbliższe miejsce</button>
            <button onClick={() => setContent(setRankingsContent)}>Ranking</button>
            <button className='button6' onClick={() => setContent(setRateContent)}>Oceń</button>
          </div>

                  <div className="selected-panel-container">
                      {showSuccessPopup && (
                          <div className="success-popup">
                              <p>Miejsce zostało przekazane do weryfikacji</p>
                              <button onClick={() => setShowSuccessPopup(false)}>Zamknij</button>
                          </div>
                      )}
            {addEditContent && <div className="control-panel">
              <h1>{editMode ? "Edytuj miejsce na mapie" : "Dodaj nowe miejsce do mapy"}</h1>
              <PlaceInputs 
                inputs={placeInputs}
                setInputs={setPlaceInputs}
                placeTypes={placeTypes}
              />
              <button onClick={editMode ? executeEditPlace : executeAddPlace}>{editMode ? "Edytuj miejsce" : "Dodaj miejsce"}</button>
            </div>}
            {deleteContent && <div className="control-panel">
              <h1>Czy na pewno chcesz usunąć?</h1>
              <br/>
              <br/>
              <h2 className='place-name'>{selectedPlace.name}</h2>
              <br/>
              <button onClick={executeDeletePlace}>Usuń</button>
            </div>}
            {findNearestContent && <div className="control-panel">
              <h1>Znajdź najbliższe miejsce</h1>
              <input
                type="text"
                name="longitude"
                placeholder="Długość geograficzna..."
                value={placeInputs?.longitude?.toFixed(6)}
                readOnly
              />
              <input
                type="text"
                name="latitude"
                placeholder="Szerokość geograficzna..."
                value={placeInputs?.latitude?.toFixed(6)}
                readOnly
              />
              <button onClick={findNearestPlace}>Znajdź miejsce</button>
            </div>}
            {rankingsContent && <div className="control-panel">
              <h1>Ranking miejsc:</h1>
              <ol className='place-ranking'>
                {ranking && ranking.map(place => {
                  return (
                    <li key={place.id}>{place.name} ★{place.averageScore}</li>
                  );
                })}
              </ol>
            </div>}
            {rateContent && <div className="control-panel">
              <br />
              <h1>Oceń miejsce</h1><br /><br />
              <h2 className='place-name'>{selectedPlace.name}</h2><br />
              <Rating rating={rating ? rating.score : 0} onStarClick={handleStarClick} />
              <textarea
                name="comment"
                placeholder="Komentarz..."
                rows="4"
                onChange={e => setRating({...rating, text: e.target.value})} />
              <br />
              <button onClick={executeRate}>Oceń</button>
            </div>}
          </div>
          <div className='close-controls-button-container'>
            <i className="close-controls-button fa-solid fa-xmark" onClick={() => setControlsActive(false)}></i>
          </div>
        </div>
      </div>
    </>
  );
}