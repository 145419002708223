import './Admin.css';
import { useState } from 'react';
import { getInactivePlaces, deletePlace, activatePlace, getPlaceTypes, addPlaceType, deletePlaceType } from '../../services/PlaceService';
import { getUsers } from '../../services/UserService';

export default function Admin() {
  const [places, setPlaces] = useState([]);
  const [users, setUsers] = useState([]);
  const [placeTypes, setPlaceTypes] = useState([]);
  const [newPlaceType, setNewPlaceType] = useState([]);
  
  useState(() => {
    getInactivePlaces()
      .then(data => setPlaces(data));
  }, [places]);
  
  useState(() => {
    getUsers()
      .then(data => setUsers(data));
  }, [users]);

  useState(() => {
    getPlaceTypes()
      .then(data => setPlaceTypes(data));
  }, [placeTypes]);

  const handleAcceptPlace = (placeId) => {
    activatePlace(placeId)
      .then(response => {
        if (!response) {
          console.error("Aktywacja miejsca nie powiodła się.");
        } else {
          setPlaces(prevPlaces => prevPlaces.filter(place => place.id !== placeId));
        }
      });      
  };

  const handleDeletePlace = (placeId) => {
    deletePlace(placeId)
      .then(response => {
        if (!response) {
          console.error("Usunięcie miejsca nie powiodło się.");
        } else {
          setPlaces(prevPlaces => prevPlaces.filter(place => place.id !== placeId)); 
        }
      });
  };

  const handleAddPlaceType = async (e) => {
    e.preventDefault();    
    addPlaceType(newPlaceType)
      .then(response => {
        if (!response) {
          console.error("Tworzenie nowego typu miejsca nie powiodło się.");
        } else {
          getPlaceTypes().then(data => setPlaceTypes(data));
          setNewPlaceType('');
        }
      });
  };

  const handleDeletePlaceType = (placeTypeId) => {
    deletePlaceType(placeTypeId)
      .then(response => {
        if (!response) {
          console.error("Usunięcie typu miejsca nie powiodło się.");
        } else {
          setPlaceTypes(prevPlaceTypes => prevPlaceTypes.filter(placeType => placeType.id !== placeTypeId)); 
        }
      });
  };
  
  return (
    <>
      <section>
        <div className="admin-container">
          <div className="admin-subsection">
            <h2>Miejsca do akceptacji</h2>
            <table className="admin-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nazwa</th>
                  <th>Opis</th>
                  <th>Adres</th>
                  <th>Akcje</th>
                </tr>
              </thead>
              <tbody>
                {places?.map(place => (
                  <tr key={place.id}>
                    <td data-header="ID: ">{place.id}</td>
                    <td>{place.name}</td>
                    <td>{place.description}</td>
                    <td>{place.address}</td>
                    <td>
                      <button className="button-accept" onClick={() => handleAcceptPlace(place.id)}>Akceptuj</button>
                      <button className="button-delete" onClick={() => handleDeletePlace(place.id)}>Usuń</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="admin-subsection">
            <h2>Typy miejsc</h2>
              <table className="admin-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nazwa</th>
                    <th>Usuń</th>
                  </tr>
                </thead>
                <tbody>
                  {placeTypes?.map(placeType => (
                    <tr key={placeType.id}>
                      <td data-header="ID: ">{placeType.id}</td>
                      <td>{placeType.name}</td>
                      <td>
                        <button className="button-delete" onClick={() => handleDeletePlaceType(placeType.id)}>Usuń</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <input className="input-addplace" type="text" name="newplacetype" placeholder="Dodaj nowy typ miejsc" value={newPlaceType} onChange={(e) => setNewPlaceType(e.target.value)}/>
              <button className="button-accept" onClick={handleAddPlaceType}>Dodaj</button>
          </div>     
          <div className="admin-subsection">
            <h2>Użytkownicy</h2>
            <table className="admin-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Rola</th>
                  <th>Username</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {users?.map(user => (
                  <tr key={user.id}>
                    <td data-header="ID: ">{user.id}</td>
                    <td>{user.role === 0 ? "użytkownik" : "administrator"}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
