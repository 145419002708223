import { Link } from 'react-router-dom'
import './Navbar.css';
import { useAuth } from '../../auth/AuthProvider';
import { useEffect, useState } from 'react';
import Hamburger from '../hamburger/Hamburger';

export default function Navbar() {
    const {loading, username, role, logout} = useAuth();
    const [navbarItems, setNavbarItems] = useState(null);

    const [active, setActive] = useState(false);
    const handleClick = () => setActive(prev => !prev);
    let activeClass = active ? ' active' : '';

    useEffect(() =>{
        if(!loading && username) {
            setNavbarItems(
                <>
                    {
                        role === 'Admin' && 
                        <Link to='/admin' className='navbar-button' onClick={handleClick}>ADMIN</Link>
                    }
                    <Link to='/user' className="navbar-button" onClick={handleClick}>PANEL</Link>
                    <Link to='/settings' className="navbar-button" onClick={handleClick}>USTAWIENIA</Link>
                    <button onClick={() => { logout(); handleClick(); }} className="navbar-button">WYLOGUJ</button>
                </>
            );
        }
        else {
            setNavbarItems(<Link to='/login' className="navbar-button" onClick={handleClick}>ZALOGUJ</Link>);
        }
    }, [username, role]);
    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-logo">
                    <Link to='/'>
                        <img src="/img/logo.png" alt="logo"/>
                    </Link>
                </div>
                <div className={"navbar-controls" + activeClass}>
                    {navbarItems}
                </div>
                <Hamburger active={active} onClick={handleClick} />
            </div>
        </nav>
    );
}