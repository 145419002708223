import './Settings.css';
import { useState } from 'react';
import apiConfig from "../../config/apiConfig";
import { callJsonApi } from '../../util/ApiCaller';

export default function Settings() {
    const [usernameControls, toggleUsernameControls] = useState(false);
    const [passwordControls, togglePasswordControls] = useState(false);
    const [emailControls, toggleEmailControls] = useState(false);

    const toggleContent = (toggleFunc) => {
        toggleUsernameControls(false);
        togglePasswordControls(false);
        toggleEmailControls(false);
        toggleFunc(true);
    };

    const [newUsername, setNewUsername] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");

    const executeChangeUsername = async (e) => {

        e.preventDefault();
        var successMessageElement = document.getElementById("success-message");
        var errorMessageElement = document.getElementById("error-message");

        const usernameBody = {
            newUsername: newUsername
        };

        const { status } = await callJsonApi(apiConfig.changeUsername, "POST", usernameBody);
        if (status === 200) {
            successMessageElement.innerHTML = "Login został zmieniony";
            errorMessageElement.innerHTML = "";
        } else {
            successMessageElement.innerHTML = "";
            errorMessageElement.innerHTML = "Zmiana loginu nieudana";
            return;
        }  
    };

    const executeChangePassword = async (e) => {

        e.preventDefault();
        var successMessageElement = document.getElementById("success-message");
        var errorMessageElement = document.getElementById("error-message");

        const passwordBody = {
            oldPassword: oldPassword,
            newPassword: newPassword
        };

        const { status } = await callJsonApi(apiConfig.changePassword, "POST", passwordBody);
        if (status === 200) {
            successMessageElement.innerHTML = "Hasło zostało zmienione";
            errorMessageElement.innerHTML = "";
        } else {
            successMessageElement.innerHTML = "";
            errorMessageElement.innerHTML = "Zmiana hasła nieudana";
            return;
        }   

    };

    const executeChangeEmail = async (e) => {

        e.preventDefault();
        var successMessageElement = document.getElementById("success-message");
        var errorMessageElement = document.getElementById("error-message");

        const emailBody = {
            newEmail: newEmail
        };

        const { status } = await callJsonApi(apiConfig.changeEmail, "POST", emailBody);
        if (status === 200) {
            successMessageElement.innerHTML = "Adres email został zmieniony";
            errorMessageElement.innerHTML = "";
        } else {
            successMessageElement.innerHTML = "";
            errorMessageElement.innerHTML = "Zmiana adresu email nieudana";
            return;
        } 

    };

    return (
        <>
            <div className='settings-container'>
                <div className='settings-controls'>
                    <div className="settings-buttons-container">
                        <button onClick={() => toggleContent(toggleUsernameControls)}>Zmień login</button>
                        <button onClick={() => toggleContent(togglePasswordControls)}>Zmień hasło</button>
                        <button onClick={() => toggleContent(toggleEmailControls)}>Zmień adres email</button>
                    </div>

                    <div className="settings-selected-panel-container">
                        {usernameControls && <div className="settings-control-panel">
                            <h1>Zmień login</h1><br /><br />

                            <label htmlFor="username">Wprowadź nowy login</label><br />
                            <input type="text" name="newUsername" placeholder="Nowy login..." onChange={(e) => setNewUsername(e.target.value)} /><br />

                            <button onClick={executeChangeUsername}>Potwierdź zmianę loginu</button>
                            <div id="success-message"></div>
                            <div id="error-message"></div>
                        </div>}
                        {passwordControls && <div className="settings-control-panel">
                            <h1>Zmień hasło</h1><br /><br />

                            <label htmlFor="username">Wprowadź obecne hasło</label><br />
                            <input type="password" name="oldPassword" placeholder="Obecne hasło..." onChange={(e) => setOldPassword(e.target.value)} /><br /><br />

                            <label htmlFor="username">Wprowadź nowe hasło</label><br />
                            <input type="password" name="newPassword" placeholder="Nowe hasło..." onChange={(e) => setNewPassword(e.target.value)} /><br />

                            <button onClick={executeChangePassword}>Potwierdź zmianę hasła</button>
                            <div id="success-message"></div>
                            <div id="error-message"></div>
                        </div>}
                        {emailControls && <div className="settings-control-panel">
                            <h1>Zmień adres email</h1><br /><br />

                            <label htmlFor="username">Wprowadź nowy adres email</label><br />
                            <input type="text" name="newEmail" placeholder="Nowy adres email..." onChange={(e) => setNewEmail(e.target.value)} /><br />

                            <button onClick={executeChangeEmail}>Potwierdź zmianę adresu email</button>
                            <div id="success-message"></div>
                            <div id="error-message"></div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}