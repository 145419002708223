import './Home.css';
import { useEffect, useState } from 'react';
import { getPlaces } from '../../services/PlaceService';
import PlacesMap from '../placesMap/PlacesMap';

export default function Home() {
  const [places, setPlaces] = useState(null);

  useEffect(() => {
    getPlaces().then((data) => setPlaces(data));
  }, []);

  return (
    <>
      <div className='home-container'>
        <div className='map-container full'>
          <div id='map'>
            <PlacesMap places={places} />
          </div>
        </div>
      </div>
    </>
  );
}
