import './PlacesMap.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import { useEffect } from 'react';
import { createMarkers } from '../Marker';

export let map;

function PlacesMap({ places }) {    
  
  useEffect(() => {    
    
    if(!map || document.getElementById("map-content").innerHTML === "") {
      map = new Map({
        target: 'map-content',
        layers: [
          new TileLayer({
            source: new OSM(),
          })
        ],
        view: new View({
          center: fromLonLat([18.6466, 54.3485]),
          zoom: 12,
          maxZoom: 20
        })
      });
    }

    if(places) {
      map.getLayers().forEach(layer => {
        if(layer && layer.get('name') === 'markers') {
          map.removeLayer(layer);
        }
      })
      map.addLayer(createMarkers(places))
    }
  }, [places]);

  return (
    <div id="map-content"></div>
  );
}

export default PlacesMap;
