export default ({ inputs, setInputs, placeTypes}) => {
    return(
        <>
        <input type="text" placeholder="Nazwa..." value={inputs.name ? inputs.name : ''} onChange={e => setInputs({...inputs, name: e.target.value})} />
        <input type="text" placeholder="Adres..." value={inputs.address ? inputs.address : ''} onChange={e => setInputs({...inputs, address: e.target.value})} />
        <input type="text" placeholder="Opis..." value={inputs.description ? inputs.description : ''} onChange={e => setInputs({...inputs, description: e.target.value})} />
        <input type="text" placeholder="Dostępność..." value={inputs.availability ? inputs.availability : ''} onChange={e => setInputs({...inputs, availability: e.target.value})} />
        <input
          type="text"
          name="longitude"
          placeholder="Długość geograficzna..."
          value={inputs.longitude && inputs.longitude.toFixed(6)}
          readOnly
        />
        <input
          type="text"
          name="latitude"
          placeholder="Szerokość geograficzna..."
          value={inputs.latitude && inputs.latitude.toFixed(6)}
          readOnly
        />
        <select value={inputs.placeTypeId ? inputs.placeTypeId : placeTypes[0].id} onChange={e => setInputs({...inputs, placeTypeId: e.target.value})}>
            {placeTypes.map(placeType => (
                <option key={placeType.id} value={placeType.id}>{placeType.name}</option>
            ))}
        </select>
        </>
    );
}