import { useAuth } from '../auth/AuthProvider';
import './Popup.css'

const PopupRatings = ({ratings}) => {
  if(!ratings) {
    return <></>;
  }

  return (
    <>
    {ratings.map(rating => {
      return (
        <div className='popup-rating'>
        <div className='popup-rating-header'>
          <h4>{rating.createdByUsername}</h4>
          <h4>★ {rating.score}</h4>
        </div>
        {rating.text && 
        <div className='popup-rating-comment'>
          <h4>Komentarz:</h4>
          <div className='popup-rating-comment-text'>{rating.text}</div>
        </div>}
        </div>
      )
    })}
    </>
  );
}

const Popup = ({place, ratings, setSelectedPlace, toggleContentMethods}) => {
  const popupClose = () => {
    setSelectedPlace({});
  }
  const {loading, role} = useAuth();

  return (
    <div className="popup-content">
      <div className="popup-header">
        <h2 className="popup-title">{place?.name}</h2>
        <h2 className="popup-avg-rating">★ {place?.averageScore}</h2>
      </div>
      <div className="popup-info">
        <p className="coordinates">Współrzędne: {place?.latitude?.toFixed(6)}, {place?.longitude?.toFixed(6)}</p>
        <p className="address">Adres: {place?.address}</p>
        <p className="description">Opis: {place?.description}</p>
      </div>
      <h3>Opinie:</h3>
      <div className="popup-ratings">
        <PopupRatings ratings={ratings} />
      </div>
      <div className="popup-buttons">
        {
          !loading && role === 'Admin' &&
          <button id="delete" className="popup-button" onClick={() => toggleContentMethods.setContent(toggleContentMethods.setDeleteContent)}>Usuń</button>
        }
        <button id="rank" className="popup-button" onClick={() => toggleContentMethods.setContent(toggleContentMethods.setRateContent)}>Oceń</button>
        <button className="popup-close" onClick={popupClose}>Zamknij</button>
      </div>
    </div>
  );
}

export default Popup;