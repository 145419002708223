import './Hamburger.css';

const Hamburger = (props) => {
    return (
        <div 
        className={'hamburger' + (props.active ? ' active' : '')} 
        onClick={props.onClick}>
            <div className='hamburger-line line-1'/>
            <div className='hamburger-line line-2'/>
            <div className='hamburger-line line-3'/>
        </div>
    );
}

export default Hamburger;