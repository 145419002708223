import { createContext, useContext, useState } from "react";
import { callJsonApi } from "../util/ApiCaller";
import apiConfig from "../config/apiConfig";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [username, setUsername] = useState(undefined);
    const [role, setRole] = useState('Guest');
    const [loading, setLoading] = useState(true);

    callJsonApi(apiConfig.username, "GET")
      .then(response => {
        if(response.status === 200) {
          setUsername(response.data);
        }
      })
    callJsonApi(apiConfig.role, "GET")
      .then(response => {
        if(response.status === 200) {
          setRole(response.data);
        }
        setLoading(false);
      })

    const handleLogin = async(username, password) => {
        const { status } = await callJsonApi(apiConfig.login, "POST", { username, password });
        if(status === 200) {
            let { data } = await callJsonApi(apiConfig.username, "GET");
            setUsername(data);
            const username = data;
            data = (await callJsonApi(apiConfig.role, "GET")).data;
            setRole(data);
            return username;
        }
        return undefined;
    };
  
    const handleLogout = async() => {
        await callJsonApi(apiConfig.logout, "POST");
        
        setUsername(null);
        setRole('Guest');
    };
  
    const value = {
      loading,
      username,
      role,
      login: handleLogin,
      logout: handleLogout,
    };

    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
